import React from "react";
import PropTypes from "prop-types";
import "./header.scss";
import { isProductPage } from "app/utils/urlDataExtractor";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";

const Header = ({ match, fallbackRedirectUrl, useBrowserBackOnProductPage }) => {
	const isFicheProduitPage = isProductPage(match.path);

	return (
		<header className="header">
			<div className="first-menu">
				<div className="header__brand">
					<div className="header__back">
						{isFicheProduitPage && (
							<BackButton
								forceBrowserBack={useBrowserBackOnProductPage}
								to={{
									pathname: fallbackRedirectUrl,
								}}
								label=""
							/>
						)}
					</div>

					<a href="https://www.verychic.com/fr/">
						<img
							alt="VeryChic"
							className="logo"
							src="https://www.verychic.com/app/shared/core/resources/images/logo_fr.png"
						/>
					</a>

					<div className="header__empty" />
				</div>

				<ul className="menu">
					<li>
						<div className="menu-item">
							<a href="https://www.verychic.com/fr/tonight">
								<div className="menu-icon tonight-icon" />
								<p className="data">Dernière minute</p>
							</a>
						</div>
					</li>

					<li className="sep" />

					<li>
						<div className="menu-item">
							<a
								target="_blank"
								rel="nofollow noopener noreferrer"
								href="https://fr.gift.verychic.com/?utm_source=verychic&utm_medium=header-www&utm_campaign=menu"
							>
								<div className="menu-icon cadeau-icon" />
								<p className="data">Carte cadeau</p>
							</a>
						</div>
					</li>

					<li className="sep" />
					<li>
						<div className="menu-item">
							<a
								rel="nofollow noopener noreferrer"
								href="https://www.verychic.com/fr/favourites"
							>
								<div className="menu-icon heart-icon" />
								<p className="data">Favoris</p>
							</a>
						</div>
					</li>
					<li className="sep" />
					<li>
						<div className="menu-item">
							<a rel="nofollow" href="https://www.verychic.com/fr/viewed">
								<div className="menu-icon clock-icon" />
								<p className="data">Consultées</p>
							</a>
						</div>
					</li>
					<li className="sep" />
					<li>
						<div className="menu-item">
							<a rel="nofollow" href="https://www.verychic.com/fr/closest">
								<div className="menu-icon proximite-icon" />
								<p className="data">À proximité</p>
							</a>
						</div>
					</li>
					<li className="sep" />
					<li>
						<div className="menu-item compte">
							<a rel="nofollow" href="https://www.verychic.com/fr/account">
								<div className="menu-icon user-icon" />
								<p className="data">Mon Compte</p>
							</a>
						</div>
					</li>
				</ul>
			</div>
		</header>
	);
};

Header.propTypes = {
	match: PropTypes.object,
	fallbackRedirectUrl: PropTypes.string,
	useBrowserBackOnProductPage: PropTypes.bool,
};

Header.defaultProps = {
	match: {},
};

export default React.memo(Header);
